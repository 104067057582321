import React from "react";
import theme from "theme";
import { Theme, Text, Strong, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"faq"} />
		<Helmet>
			<title>
				Trailblaze24
			</title>
			<meta name={"description"} content={"Trailblaze24 пропонує оренду квадроциклів для тих, хто цінує свободу, екстрим та незабутні емоції."} />
			<meta property={"og:title"} content={"Trailblaze24"} />
			<meta property={"og:description"} content={"Trailblaze24 пропонує оренду квадроциклів для тих, хто цінує свободу, екстрим та незабутні емоції."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6745953f28b5e30023ae7079/images/BRP-Can-Am-Outlander-MAX-570.png?v=2024-11-27T09:00:38.548Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6745953f28b5e30023ae7079/images/BRP-Can-Am-Outlander-MAX-570.png?v=2024-11-27T09:00:38.548Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6745953f28b5e30023ae7079/images/BRP-Can-Am-Outlander-MAX-570.png?v=2024-11-27T09:00:38.548Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6745953f28b5e30023ae7079/images/BRP-Can-Am-Outlander-MAX-570.png?v=2024-11-27T09:00:38.548Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6745953f28b5e30023ae7079/images/BRP-Can-Am-Outlander-MAX-570.png?v=2024-11-27T09:00:38.548Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6745953f28b5e30023ae7079/images/BRP-Can-Am-Outlander-MAX-570.png?v=2024-11-27T09:00:38.548Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6745953f28b5e30023ae7079/images/BRP-Can-Am-Outlander-MAX-570.png?v=2024-11-27T09:00:38.548Z"} />
		</Helmet>
		<Components.Header />
		<Section padding="100px 0 100px 0" background="--color-darkL2" quarkly-title="FAQ-LocalMasteryInsight">
			{"    "}
			<Text margin="0px 0px 15px 0px" font="normal 600 42px/1.2 --fontFamily-sans" color="--light">
				{"        "}FAQ{"\n    "}
			</Text>
			{"    "}
			<Text margin="0px 0px 70px 0px" font="normal 300 20px/1.5 --fontFamily-sansHelvetica" color="#b2bac1" sm-margin="0px 0px 50px 0px">
				<Strong>
					Ми зібрали найпоширеніші запитання, щоб допомогти вам отримати повну інформацію про наші послуги та зробити вашу поїздку максимально комфортною.
				</Strong>
			</Text>
			{"    "}
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="50px 50px"
				md-grid-template-columns="1fr"
				sm-grid-gap="35px 0"
			>
				{"        "}
				<Box min-width="100px" min-height="100px">
					{"            "}
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--light">
						<Strong>
							1. Чи потрібні водійські права для оренди квадроцикла?
						</Strong>
					</Text>
					{"            "}
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#b2bac1">
						Ні, для оренди квадроцикла водійські права не потрібні. Однак, усі учасники повинні пройти короткий інструктаж перед поїздкою.
					</Text>
					{"        "}
				</Box>
				{"        "}
				<Box min-width="100px" min-height="100px">
					{"            "}
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--light">
						<Strong>
							2. Який вік мінімально допустимий для водіння квадроцикла?
						</Strong>
					</Text>
					{"            "}
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#b2bac1">
						Мінімальний вік для оренди – 18 років. Для молодших учасників пропонуємо можливість бути пасажиром із дорослим.
					</Text>
					{"        "}
				</Box>
				{"        "}
				<Box min-width="100px" min-height="100px">
					{"            "}
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--light">
						<Strong>
							3. Що входить у вартість оренди?
						</Strong>
						<br />
						{"\n            "}
					</Text>
					{"            "}
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#b2bac1">
						У вартість входить оренда квадроцикла, шолом, базовий інструктаж, паливо для маршруту та технічна підтримка під час катання.
					</Text>
					{"        "}
				</Box>
				{"        "}
				<Box min-width="100px" min-height="100px">
					{"            "}
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--light">
						<Strong>
							4. Чи можна орендувати квадроцикл на групу людей?
						</Strong>
					</Text>
					{"            "}
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#b2bac1">
						Так, ми надаємо послуги для групових заїздів. Ви можете обрати один із наших групових пакетів для максимальної вигоди.
					</Text>
					{"        "}
				</Box>
				{"        "}
				<Box min-width="100px" min-height="100px">
					{"            "}
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--light">
						<Strong>
							5. Як я можу забронювати квадроцикл?
						</Strong>
					</Text>
					{"            "}
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#b2bac1">
						Ви можете забронювати квадроцикл онлайн через нашу форму бронювання на сайті або зателефонувати за контактним номером.
					</Text>
					{"        "}
				</Box>
				<Box min-width="100px" min-height="100px">
					{"            "}
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--light">
						<Strong>
							5. Як я можу забронювати квадроцикл?
						</Strong>
					</Text>
					{"            "}
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#b2bac1">
						Ви можете забронювати квадроцикл онлайн через нашу форму бронювання на сайті або зателефонувати за контактним номером.
					</Text>
					{"        "}
				</Box>
				<Box min-width="100px" min-height="100px">
					{"            "}
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--light">
						<Strong>
							7. Який одяг краще взяти із собою?
						</Strong>
					</Text>
					{"            "}
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#b2bac1">
						Рекомендуємо обирати зручний спортивний одяг та міцне взуття. У холодну пору року ми надаємо теплі костюми та рукавиці.
					</Text>
					{"        "}
				</Box>
				{"        "}
				<Box min-width="100px" min-height="100px">
					{"            "}
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--light">
						<Strong>
							8. Чи безпечний квадроцикл для їзди?
						</Strong>
					</Text>
					{"            "}
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#b2bac1">
						Так, наші квадроцикли регулярно перевіряються на технічну справність. Крім того, усі клієнти проходять інструктаж, а також отримують необхідне захисне спорядження.
					</Text>
					{"        "}
				</Box>
				{"    "}
			</Box>
		</Section>
		<Components.Footernew />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"673f21f141a0cf0024a5b026"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});